define("discourse/plugins/relativity-report-job/discourse/controllers/admin-plugins-report-job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    actions: {
      forceSendNow() {
        Discourse.ajax("/admin/plugins/report-job/trigger", {
          type: "PUT",
          data: {}
        });
      }
    }
  });
});