define("discourse/plugins/relativity-report-job/discourse/templates/admin/plugins-report-job", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button label="report_job.show" class="btn-primary" action="forceSendNow"}}
  */
  {
    "id": "KNiOCk3n",
    "block": "[[[1,[28,[35,0],null,[[\"label\",\"class\",\"action\"],[\"report_job.show\",\"btn-primary\",\"forceSendNow\"]]]]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/relativity-report-job/discourse/templates/admin/plugins-report-job.hbs",
    "isStrictMode": false
  });
});